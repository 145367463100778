import { useState, useEffect } from "react";
import Chat from "./Chat.js";
import { ethers } from "ethers";
import "./Homepage.css";
import ABI from "./ABI.json";
function Homepage(props) {


    const [message, setMessage] = useState("");
    const [allChats, setAllChats] = useState([]);
    const [pagination, setPagination] = useState(0);
    const [NFT, setNFT] = useState(1000);
    const [NFTlist, setNFTlist] = useState([]);
    const [current, setCurrent] = useState(0)
    const [chain, setChain] = useState(0)
    const chatAddress = "0xA61a26476c938610E057aeF454E005e5E3aa0903"

   

    const sendMessage = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(chatAddress, ABI, signer);

        const tryToSend = await contract.addMessage(message, NFT);
        const receipt = await tryToSend.wait()
        
        if (receipt.confirmations) {
            getMessages();
            window.location.reload();
        }


       

    }

    const getMessages = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(chatAddress, ABI, signer);
        const currentAddress = await provider.getSigner().getAddress();
       
        setCurrent(currentAddress);
        const network = await provider.getNetwork();
        const currentChain = network.chainId;
        setChain(Number(currentChain))

        const totalMessage = await contract.totalMessages();
        const page = 10;



        
        const amountOfNFTs = await contract.balanceOf(currentAddress);

        setNFTlist([]);
        for (var i = 0; i < amountOfNFTs; i++) {
            const currentNFT = await contract.tokenOfOwnerByIndex(currentAddress, i);
            setNFTlist(old => [...old, currentNFT]);
            if (i == 0) {
                setNFT(currentNFT);//this sets their selected NFT to the first NFT they have
            } 
        }
        




        const starting = totalMessage - (page * pagination) - 1
        setAllChats([]);

        for (var i = starting; i > starting - page; i--) {

            if (i >= 0) {
                const currentMessage = await contract.Messages(i);
                setAllChats(prevChat => [...prevChat, currentMessage]);
            }

        }

    }

    const changePolygon = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const connect = await provider.send("eth_requestAccounts");

        if (window.ethereum) {

            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: "0x89" }],
            });

        }
    }

    const connectMM = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const connect = await provider.send("eth_requestAccounts");

        
    }

    const getWalletAddress = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);

                const connect = await provider.send("eth_requestAccounts");

     
           
           

           
            //const currentAddress = await provider.getSigner().getAddress(); 
            //console.log("CUR" + currentAddress)
            //if (currentAddress == 0x0000000000000000000000000000000000000000) {
            //    window.location.reload();
            //}
            
            
                
           
            



        }





    }

    const reLoad = () => {
        window.location.reload();
    }

    if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum.on('chainChanged', reLoad);
        window.ethereum.on('accountsChanged', reLoad)
    }

    useEffect(() => {
        getWalletAddress();
    }, []);



    useEffect(() => {
        getMessages();
        
    }, [pagination]);




    const back = async () => {
        
        setPagination(old => old + 1)
        //getMessages()
        
    }

    const forward = async () => {
        if (pagination != 0) {
            setPagination(old => old - 1)
            //getMessages()
            
        }

    }




    return (

        
        

        <section>
            <div class="hero">

                <div style={{ display: current == 0 ? 'block' : 'none' }} id="a">
                    <h1> Please connect Metamask! </h1>
                    <button class="mint-button"><a onClick={connectMM} href="#">Connect</a></button>
                </div>
                <div style={{ display: NFTlist.length > 0 ? 'block' : 'none' }} id="a">
                <button id="b"class="header-cta"><a onClick={back} href="#">Back</a></button>
                    <button id="c" class="header-cta"><a onClick={forward} href="#">Forward</a></button>
                    </div>
                <div style={{ display: NFTlist.length > 0 ? 'block' : 'none' }} className="chatMessage">
                    {allChats.map((item) => (
                        <Chat text={item.sentMessage} data={item.sender} nft={item.nftID} timestamp={item.timestamp} />
                        
                    ))}

                         
                </div>
                <div style={{ display: chain !== 137  && current !== 0 ? 'block' : 'none' }} id="a">
                    <h1> Please switch to Polygon! </h1>
                    <button class="mint-button"><a onClick={changePolygon} href="#">Switch</a></button>
                </div>

                <div style={{ display: NFTlist.length == 0 && current !== 0 && chain== 137 ? 'block' : 'none' }}>
                    <h1>Please mint an NFT!  </h1>
                </div>

                



              

                <div style={{ display: NFTlist.length>0 ? 'block' : 'none' }} className="Send">
                    


                    <div class="dropdown" >
                        <button class="dropbtn" style={{
                            backgroundImage: "url(" + "https://amethyst-voluntary-porcupine-635.mypinata.cloud/ipfs/QmX72rQFJMjmKrqKWfDcoauq1P8MYALfKGYdsH6nT9jNck/" + NFT + ".png" + ")",
                            backgroundSize: "cover",
                            width: "80px"
                        }}></button>
                        <div class="dropdown-content">
                            {NFTlist.map((item) => (
                                
                                <img className="selectItem" key={item.toString()} onClick={() =>  setNFT(item.toString()) } src={"https://amethyst-voluntary-porcupine-635.mypinata.cloud/ipfs/QmX72rQFJMjmKrqKWfDcoauq1P8MYALfKGYdsH6nT9jNck/" + item.toString() + ".png"} height="80px" />

                            ))}
                            

                        </div>
                    </div>




                          <textarea
                        id="chatInput"
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        style={{ height: "82px"}}
                          />
                    <button style={{
                        height: "47px"
                    }} id="sendButton" class="header-cta"><a id ="sendA" onClick={ sendMessage } href="#" > Send</a></button>
                    
                      </div>


                
                      
                      
                  </div>
                </section> 
             

    );



}

export default Homepage;