import "./Mint.css";
import { ethers } from "ethers";
import { useState, useEffect } from "react";
import ABI from "./ABI.json";
function Mint(props) {

    const [priceD, setPriceD] = useState(0);
    const [NFT, setNFT] = useState(0);
    const [canMint, setCanMint] = useState(false);
    const [chain, setChain] = useState(0)

    const chatAddress = "0xA61a26476c938610E057aeF454E005e5E3aa0903";
    const mint = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(chatAddress, ABI, signer);
        const price = await contract.price();

        setPriceD(<p> Price: {ethers.utils.formatEther(price.toString())} Matic</p>);
        const tryToMint = await contract.safeMint({ value: price });

        const tryRX = await tryToMint.wait()
        if (tryRX.confirmations) {
            window.location.href="/"
        }
        
    }


    const getPrice = async () => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(chatAddress, ABI, signer);
        const network = await provider.getNetwork();
        const currentChain = network.chainId;
        setChain(Number(currentChain))
        const currentAddress = await provider.getSigner().getAddress();
        setCurrent(currentAddress);

        if (Number(currentChain) == 137) {
            const price = await contract.price();

            const amount = await contract.totalSupply();
            setNFT(Number(amount));
            


            const add = await provider.getSigner().getAddress();
            const balance = await provider.getBalance(add)

            if (Number(balance) < Number(price)) {
                setPriceD(<div><p>Not Enough Matic To Mint</p>
                    <p>Balance: {ethers.utils.formatEther(balance.toString())}</p>
                    <p>Price: {ethers.utils.formatEther(price.toString())}</p> </div>)
            } else {
                setPriceD(<p> Price: {ethers.utils.formatEther(price.toString())} Matic</p>);
                setCanMint(true);
            }


           
            const amountOfNFTs = await contract.balanceOf(currentAddress);

            setNFTlist([]);
            for (var i = 0; i < amountOfNFTs; i++) {
                const currentNFT = await contract.tokenOfOwnerByIndex(currentAddress, i);
                setNFTlist(old => [...old, currentNFT]);
                
            }
        } 

        
        



    }
    const [NFTlist, setNFTlist] = useState([]);
    const [current, setCurrent] = useState(0)



    const reLoad = () => {
        window.location.reload();
    }

    if (window.ethereum && window.ethereum.isMetaMask) {
        window.ethereum.on('chainChanged', reLoad);
        window.ethereum.on('accountsChanged', reLoad)
    }



    useEffect(() => {
        getPrice();
    },[])

    return (
        <div>
            
            <div style={{ display: current == 0 ? 'block' : 'none' }} id="a">
                <h1> Please connect Metamask!  </h1>
            </div>

            <div style={{ display: chain == 137 ? 'none' : 'block' }} id="a">
                <h1> Please switch to Polygon! </h1>
            </div>
            
            <div style={{ display: current !== 0 && chain == 137 ? 'block' : 'none' }} >
            


            <h1> Next NFT: </h1>
            <img className="minting" src={"https://amethyst-voluntary-porcupine-635.mypinata.cloud/ipfs/QmX72rQFJMjmKrqKWfDcoauq1P8MYALfKGYdsH6nT9jNck/" + NFT + ".png"} />
                {priceD}
            {canMint ? <button class="mint-button"><a onClick={mint} href="#">Mint</a></button> : <p></p>}
            </div>
        </div>


    );



}

export default Mint;