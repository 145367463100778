import './App.css';
import { useState } from "react";

function Chat(props) {

    const imageURL = "https://amethyst-voluntary-porcupine-635.mypinata.cloud/ipfs/QmX72rQFJMjmKrqKWfDcoauq1P8MYALfKGYdsH6nT9jNck/" + props.nft + ".png";


    const unixTime = props.timestamp;
    const date = new Date(unixTime * 1000);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


    const timeSent = date.toLocaleString("en-US")


    return (



        <div className="chatText">
            <div className="chatImage">
                <img className="chatImage" src={imageURL} />
                <p>{props.nft.toString()}</p>
            </div>

            <div className="mainWrap">
                <div className="chatBubble">
                    <p>{props.text}</p>
                    
                </div>

                <div className="bubbleInfo">
                    <p id="data">{props.data}</p>
                    <p id="time">{timeSent} </p>
                    
                </div>
            </div>
        </div>

    );
}

export default Chat;